<p class="page-section-content" *ngIf="includeDescription">
	{{ currPageData[0].description }}
</p>
<p class="page-section-content alias-content" *ngIf="otherNames">
	Other names: {{ currPageData[0].otherNames }}
</p>
<div class="page-overview-link kendo-link" *ngIf="currPageData[0].kendoLink !== ''">
	<div class="link-kendo-text">
		This is a Kendo component. Refer to the
		<a class="redirect" [attr.href]="currPageData[0].route + '#behaviors'">behaviors section</a>
		below for the features that Shield supports.
	</div>
	<div>
		<a [attr.href]="currPageData[0].kendoLink" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="18px"
				height="18" viewBox="0 0 49 60.3">
				<path fill="#5ce500"
					d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
				</path>
				<path fill="#5ce500"
					d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
				</path>
			</svg>Kendo</a>- Developer resource
	</div>
</div>
<div class="page-overview-link" *ngIf="currPageData[0].designLink !== ''">
	<a [attr.href]="currPageData[0].designLink" target="_blank"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 200 300" width="20" height="20">
			<style type="text/css">
				.st0 {
					fill: #0acf83;
				}

				.st1 {
					fill: #a259ff;
				}

				.st2 {
					fill: #f24e1e;
				}

				.st3 {
					fill: #ff7262;
				}

				.st4 {
					fill: #1abcfe;
				}
			</style>
			<title>Figma.logo</title>
			<desc>Created using Figma</desc>
			<path id="path0_fill" class="st0"
				d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z" />
			<path id="path1_fill" class="st1" d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z" />
			<path id="path1_fill_1_" class="st2" d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z" />
			<path id="path2_fill" class="st3" d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z" />
			<path id="path3_fill" class="st4"
				d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z" />
		</svg>Figma</a>- Design Resource
</div>